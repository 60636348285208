.default-text {
  display: inline-block;
  margin: 0;
  /* font-family: -apple-system, 'BlinkMacSystemFont', 'Roboto,' sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #595959;
}

.default-text__secondary {
  color: #aeb5bd;
}

.default-text__accent {
  color: #343A40;
}

.default-text__gray {
  color: #6C757D
}

.default-text__white {
  color: #ffff
}

.default-text__gold {
  color: #BDAB8A;
}

.default-text__italic {
  font-style: italic;
}

.default-text__thin {
  font-weight: 300;
}

.default-text__semiBold {
  font-weight: 500;
}

.default-text__bold {
  font-weight: 700;
}
