.radio__group__button label {
	border-radius: 0px !important;
	color: rgba(59, 75, 87, 0.65);
}

.radio__group__button label:first-child {
	border-radius: 5px 0px 0px 5px !important;
}

.radio__group__button label:last-child {
	border-radius: 0px 5px 5px 0px !important; 
}
