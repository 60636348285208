@import 'antd/dist/antd.css';
@import "./globalStyles/global.css" ;

*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: none;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
