.profile-card__content__footer {
  display: flex;
  justify-content: flex-end;
  padding-left: 25px;
  padding-right: 25px;
}
.profile-card__content__footer__button {
  margin-left: 20px;
  border-radius: 5px;
  padding: 6px 15px;
  border: 1px solid #1890ff;
}
