.admin-form {
  border: none;
  border-radius: 10px;
  box-shadow: 0 10px 32px -4px rgba(24, 39, 75, 0.1), 0 6px 14px -6px rgba(24, 39, 75, 0.12);
}

.admin-form__header {
  display: flex;
  margin: 0 0 20px 0;
  align-items: center;
  justify-content: space-between;
}

.admin-form__header__title {
  font-size: 16px;
  font-weight: 500;
  color: #3d4b57;
}

.admin-form__header__button__cancel {
  margin-right: 14px;
  color: #1890FF;
  border-color: #1890FF;
}

.admin-form__input {
  width: 328px;
}

.admin-form__radio .ant-radio-button-wrapper {
  color: rgba(59, 75, 87, 0.65);
  border-radius: none;
}

.admin-form__radio .ant-radio-button-wrapper:last-child {
  border-radius: 0 5px 5px 0;
}

.admin-form__radio .ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}

.admin-form__input__select,
.admin-form__input__date {
  width: 216px !important;
}
