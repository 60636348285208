.admin-label {
  background-color: #b44b0f;
  color: #ffff;
  padding: 10px 0;
}

.header {
  padding: 32px 50px 32px 25px;
  background-color: #DDDDDD;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo__icon {
  display: flex;
  align-items: center;
  margin-right: 24px;
}


.header-logo__text {
  font-style: italic;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 1.32em;
  color: #3D4B57;
}

.header__search {
  display: flex;
  flex: 3 1;
  justify-content: center;
}

.header__search form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header__search input {
  display: block;
  max-width: 548px;
  width: 80%;
  font-size: 16px;
  line-height: 25px;
  padding: 7px 22px;
  border: none;
  outline: none;
  border-radius: 10px 0 0 10px !important;
}

.header__search button {
  display: block;
  cursor: pointer;
  background: #3D4B57;
  font-size: 16px;
  color: #fff;
  padding: 12px 25px;
  border: none;
  border-radius: 0 10px 10px 0 !important;
}

.header-notifications {
  margin-right: 30px;
}

.header-notifications__icon {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.header-user {
  max-width: 440px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.header-user__avatar {
  flex-shrink: 0;
  position: relative;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
}

.header-user__avatar img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.header-user__name {
  font-weight: 500;
  word-break: break-all;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.125em;

  color: #3D4B57;
}

.header-user__expand {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
