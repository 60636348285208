.profile-card__recommendation__list__shimmer {
  display: flex;
}

.profile-card__recommendation__list-item {
  flex: 1 1;
}

.profile-card__recommendation__list-item_small {
  display: flex;
  align-items: center;
  flex: 0.5 0.5;
}
