.ant-steps-item-container {
	margin-bottom: 6px;
}

.ant-steps-item-title {
	font-size: 15px;
}

.ant-form-item-label > label {
	display: flex;
	color: rgba(59, 75, 87, 0.85);
	white-space: initial;
  justify-content: flex-end;
}

.ant-form-item-label {
	overflow: initial;
}

.ant-menu-item-selected {
	color: #1890ff !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu {
	background: none;
}

.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
	display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	margin-bottom: 8px;
	font-size: 11px;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
	width: calc(100% + 1px);
	color: rgba(0, 0, 0, 0.65);
}

/* .ant-btn:hover, .ant-btn:focus {
	color: #40a9ff !important;
	background: #fff;
	border-color: #40a9ff;
} */

.ant-btn-dangerous.ant-btn-text:hover, .ant-btn-dangerous.ant-btn-text:focus {
	color: #ff7875 !important;
	background: rgba(0, 0, 0, 0.018);
	border-color: transparent;
}

.ant-tag {
	margin: 4px;
}

.ant-upload-list-picture .ant-upload-list-item {
	width: 304px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #1890ff !important;
}

input,
textarea,
.ant-btn,
.ant-picker,
.ant-select-selector {
	border-radius: 5px !important;
}
