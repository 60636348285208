.default-card {
  border-radius: 15px;
}

.default-card__white {
  background-color: #fff;
}

.default-card__float {
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.1), 0px 18px 88px -4px rgba(24, 39, 75, 0.1);
}

