.footer {
  color: #fff;
  display: flex;
  align-items: center;
  background-color: #3D4B57;
  padding: 11px 35px;
}

.footer span {
  margin-left: 8.25px;
}
