.carousel {
  display: flex;
  flex-direction: column;
}

.carousel-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  margin-top: -18px;
}

.carousel-navigation {
  display: flex;
  margin-left: 40px;
}

.carousel-navigation-button {
  position: relative;
  z-index: 1;
  display: flex;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
}

.carousel-navigation-button:first-child {
  margin-right: 30px;
}

.carousel-links {
  display: flex;
}

.carousel-links__item {
  margin-right: 13px;
}

.carousel-links_item:last-child {
  margin-right: 0;
}

.carousel .slick-track {
  display: flex;
  align-items: center;
}

 .shadows-offset.carousel {
  margin: -40px;
  margin-left: -20px;
  margin-bottom: 0;
  margin-top: 0;

}
.shadows-offset.carousel .slick-list {
  padding: 40px;
  padding-left: 20px;
  padding-top: 0;

}
