.login-page__error-alert {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fef8f8;
  border: solid 1px rgba(251, 78, 78, 0.5);
  padding: 10px 15px;
  padding-left: 40px;
  margin-bottom: 24px;
  margin-top: 10px;
  color: #ff4d4f;
}
.login-page__error-alert::before {
  display: block;
  position: absolute;
  content: "";
  left: 15px;
  background-image: url("../../../../assets/icons/exclamation-circle.svg");
  background-size: 17px 17px;
  width: 17px;
  height: 17px;
}
