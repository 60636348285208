.admin-card {
  min-height: 200px;
  margin-right: 37px;
  margin-bottom: 54px;
  background-color: #f6f7f9;
}

.admin-card__content {
	min-height: 850px;
  display: flex;
}

.admin-card__aside {
  box-sizing: border-box;
  max-width: 316px;
  min-width: 316px;
  padding-top: 64px;
  padding-left: 50px;
  padding-right: 35px;
}

.admin-card__main__tabs__link {
	margin: 0 25px 15px auto;
  border-radius: 5px;
  color: #FFFFFF;
  background: #1890FF;
  padding: 5px 22px 5px;
}

.admin-card__header {
  padding: 10px 24px;
  border-radius: 15px 15px 0px 0px;
  background-color: #f3f4f5;
  display: flex;
  justify-content: space-between;
}

.admin-card__header__breadcrumbs {
  display: flex;
}

.admin-card__header__breadcrumbs__item {
  color: #aeb5bd;
}

.admin-card__header__breadcrumbs__item_active {
  color: #1890ff;
}

.admin-card__header__breadcrumbs__divider {
  margin: 0 8px;
  color: #1890ff;
}

.admin-card__header__share {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  color: #1890ff;
  font-size: 12px;
  line-height: 22px;
}

.admin-card__header__share svg {
  margin: 0 5px 0 0;
  height: 15px;
  width: 14px;
}

.admin-card__main__tabs .ant-tabs-nav {
  padding-left: 20px;
  margin-bottom: 0;
}

.admin-card__main__tabs .ant-tabs-nav-wrap {
  height: 45px;
}

.admin-card__main__tabs .ant-tabs-nav-wrap .ant-tabs-nav-list {
  align-items: flex-end;
}

.admin-card__main__tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  margin: 0 -4px 0 0;
  height: 40px;
  background-color: #F0F2F4;
  margin-left: 0 !important;
  border: none;
}

.admin-card__main__tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  margin: 0 -4px 0 0 !important;
  z-index: 1;
  height: 45px;
  background-color: #fff; 
  border-radius: 15px 15px 0 0px !important;
}

.admin-card__main__tabs .ant-tabs-top .ant-tabs-extra-content {
  display: flex;
}

.admin-card__main__tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:first-child {
  border-radius: 15px 0 0 0px;
}

.admin-card__main__tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:nth-last-child(2) {
  border-radius: 0 15px 0 0;
}

.admin-card__main {
  margin-top: 15px;
  flex: 1;
}

.admin-card__main__content {
	min-height: 850px;
  position: relative;
  z-index: 1;
  border-radius: 15px 0 15px 0px;
  padding: 0 0 25px;
  background-color: #fff;
}

.admin-card__main__tabs__item {
  cursor: pointer;
  transform: translateY(5px);
  height: 44px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #F0F2F4;
	color: #595959;
}

.admin-card__main__tabs__item:first-child {
  border-radius: 15px 0 0 0;
}

.admin-card__main__tabs__item:last-child {
  border-radius: 0 15px 0 0;
}

.admin-card__main__tabs__item.admin-card__main__tabs__item_active {
  transform: translateY(0px);
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  color: #1890FF;
}

.admin-card__community__stack {
  position: relative;
  display: flex;
}

.admin-card__community__stack__item {
  position: relative;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.admin-card__community__stack__item_counter {
  background-color: #BFBFBF;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.admin-card__main__tabs__link:hover,
.admin-card__main__tabs__link:focus {
  color: #FFFFFF;
}
