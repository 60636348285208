.Assigned-modal .ant-modal-footer {
  padding-right: 0;
  border-top: none;
}

.Admin-assigned__modal__info {
  margin: 30px 0;
}

.Admin-assigned__modal__info .ant-alert-message {
  color: #12497a;
}

.Admin-assigned__members {
  margin: 0 0 15px;
}

.Admin-assigned__members__item {
	border-bottom: 1px solid #f0f0f0;
	color: rgba(0, 0, 0, 0.85);
	padding: 16px 0 16px;
	font-weight: 300;
}

.Admin-assigned__members__item:first-child {
	color: #000000;
	font-weight: 400;
}

.Admin-assigned__modal__select {
  width: 215px;
  height: 33px;
}

.Admin-assigned__modal__footer__button {
  margin: 80px 0 0 0; 
}
