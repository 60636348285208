.planner-card {
  min-height: 200px;
  margin-right: 37px;
  margin-bottom: 54px;
  background-color: #f6f7f9;
}

.planner-card__header {
  padding: 10px 24px;
  border-radius: 15px 15px 0px 0px;
  background-color: #f3f4f5;
  display: flex;
  justify-content: space-between;
}

.planner-card__header__button svg {
  margin: 0 15px 0 0;
}

.planner-card__header__breadcrumbs {
  display: flex;
}

.planner-card__header__breadcrumbs__item {
  color: #aeb5bd;
}

.planner-card__header__breadcrumbs__item_active {
  color: #1890ff;
}

.planner-card__content {
  display: flex;
  max-height: 925px;
}

.planner-card__aside {
  display: flex;
  min-width: 316px;
  flex-direction: column;
  padding: 30px 34px 30px 48px;
  background-color: rgba(250, 250, 250, 0);
  overflow-y: auto;
}

.planner-card__aside__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: #212529;
}

.planner-card__aside__text {
  margin: 20px 0 40px 0;
  font-size: 14px;
  line-height: 1.57;
  color: #595959;
}

.planner-list__schedule__item {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  list-style: none;
}

.planner-list__schedule__time {
  width: 70px;
}

.planner-list__schedule__icon {
  position: relative;
  margin-right: 15px;
}

.planner-list__schedule__icon::after {
  content: "";
  position: absolute;
  height: 44px;
  width: 2px;
  background-color: #CED4DA;
  bottom: -4px;
  right: calc(50% - 1px);
}

.planner-list__schedule__title {
  line-height: 11px;
  width: 125px;
}

.planner-card__main__tabs .ant-picker-calendar {
  min-height: 835px;
  padding: 30px 30px 104px;
  border-radius: 15px 0 15px 15px;
}
