.pagination-crumbs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  margin-right: 8px;
}

.pagination-crumbs__elipsis {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
}
