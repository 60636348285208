.base-ViewMemberModal .ant-modal-content {
	padding: 60px 0 26px 0;
  border-radius: 10px;
	background-color: #f8f9fa;
}

.base-ViewMemberModal .ant-modal-body {
  padding: 0 26px 0 0;
}

.viewMemberModal-card__aside {
  box-sizing: border-box;
  max-width: 265px;
  min-width: 265px;
	padding-right: 10px;
  padding-left: 35px;
}

.viewMemberModal_modal {
	display: flex;
}

.viewMemberModal__main {
	width: 100%;
	background-color: #FFFFFF;
	padding: 30px 16px 20px;
	border-bottom: 1px solid #f0f0f0;
}

.viewMemberModal__main-item_title {
	margin: 0 0 16px 0;
}

.viewMemberModal__main-item__title_sub {
	font-weight: 300;
  font-size: 20px;
  line-height: 22px;
  color: #3D4B57;
}

.viewMemberModal__row_radio {
	margin: 10px 0 18px 0;
}

.viewMemberModal_modal__footer {
	margin: 24px 0 0 0;
	display: flex;
  justify-content: flex-end;
}

.base-ViewMemberModal .ant-modal-close-x {
	color: #1890ff;
}
