.notes-card {
	position: relative;
  margin: 0 37px 41px 0;
  min-height: 823px;
  background-color: #F6F7F9;
}

.notes-card__header {
  border-radius: 15px 15px 0px 0px;
  background-color: #F3F4F5;
  padding: 10px 24px;
}

.notes-card__header__breadcrumbs {
  display: flex;
}

.notes-card__header__breadcrumbs__item {
  color: #AEB5BD; 
}

.notes-card__header__breadcrumbs__item_active {
  color: #1890FF;
}

.notes-card__header__breadcrumbs__divider {
  margin: 0 8px;
  color: #1890FF;
}

.notes-card__header__breadcrumbs__item__back {
  cursor: pointer;
	background-color: #F3F4F5;
}

.notes-card__main {
	display: flex;
}

.notes-card__aside {
	width: 400px;
	padding: 64px 20px 0 20px;
}

.notes-card__person {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.notes-card__person__block {
	width: 100%;
	max-height: 600px;
	overflow-y: auto;
	padding: 0 10px;
}

.notes-card__person__newnote {
	margin: 10px 0;
	cursor: pointer;
	align-self: flex-end;
	color: #1890FF;
	background: none;
}
.notes-card__person__newnote:disabled {
	color: #AEB5BD;
	cursor: not-allowed;
}

.notes-card__form {
	width: 100%;
	min-height: 780px;
	background: #FFFFFF;
	border-radius: 0 0 20px 0;
	padding: 14px 24px;
}

.notes-card__form__buttongroup {
	margin: 0 0 72px 0;
	display: flex;
    justify-content: space-between;
}

.notes-card__form__buttongroup__cancel {
	margin: 14px 0 0 0;
	cursor: pointer;
	color: #1890FF;
	background: none;
	line-height: 17px;
}

.notes-card__form__buttongroup__cancel svg {
	margin: 0 5px 0 0;
	color: #1890FF;
}

.notes-card__form__buttongroup__save {
	color: #FFFFFF;
	background: #1890FF;
	cursor: pointer;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
	border-radius: 5px;
	padding: 5px 24px;
}
.notes-card__form__buttongroup__save:disabled {
	background: #D9D9D9;
	cursor: not-allowed;
}

.notes-card__form__select {
	width: 300px !important;
	border-radius: 5px;
}

.notes-card__form__switch {
	margin: 21px 0 30px 0;
}

.notes-card__upload-button {
	color: rgba(0, 0, 0, 0.65);
}

.notes-card_switch_text {
	color: #495057;
}

.notes-card__form__time-picker {
	width: 100%
}

.notes-card__form__date-picker {
	width: 100%
}
