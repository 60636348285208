.list-no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-no-results__text {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #a0a9ae;
}
