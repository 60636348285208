.EditMembers-card {
  margin: 0 37px 41px 0;
  min-height: 823px;
  background-color: #F6F7F9;
}

.EditMembers-card__header {
	display: flex;
	height: 42px;
	background-color: #F3F4F5;
	border-radius: 15px 15px 0px 0px;
  align-items: center;
  justify-content: space-between;
	padding: 10px 24px;
}

.EditMembers-card__header__breadcrumbs {
  display: flex;
}

.EditMembers-card__header__breadcrumbs__item {
  color: #AEB5BD; 
}

.EditMembers-card__header__breadcrumbs__item_active {
  color: #1890FF;
}

.EditMembers-card__header__breadcrumbs__divider {
  margin: 0 8px;
  color: #1890FF;
}

.EditMembers-card__main {
	display: flex;
	min-height: 823px;
}

.EditMembers-card__aside {
	min-width: 316px;
	padding: 64px 0 0 50px;
}

.EditMembers-card__form {	
	width: 100%;
	background: #FFFFFF;
	border-radius: 0 0 20px 0;
	padding: 14px 24px;
}

.EditMembers-card__header__editButton {
	color: #ff4d4f;
}

.EditMembers-card__header__editButton svg {
	margin: 0 7px 0 0;
}

.editButton__disabled {
	color: #AEB5BD;
}

.EditMembers-card__form__buttongroup__cancel {
	margin: 14px 0 0 0;
	color: #1890FF;
	background: none;
	line-height: 17px;
}

.EditMembers-card__form__buttongroup__cancel_icon {
	color: #1890FF;
	margin: 0 5px 0 0;
}

.EditMembers-card__form__title {
	margin: 30px 0 0 0;
	color: #566C76;
	font-size: 20px;
	font-weight: 400;
}

.EditMembers-card__form__footer__button {
	height: 32px;
	color: #fff !important;
  padding: 7px 18px;
	border-radius: 5px;
  background: #1890ff;
	border-color: #1890ff;
}

.EditMembers-card__form__select {
	width: 216px !important;
	border-radius: 5px;
}

.EditMembers-card__aside__wrapper {
	padding: 0 62px 0 0;
}

.EditMembers-card__form__multiple {
	max-width: 328px !important;
}

.EditMembers-card__form__button-submit {
	margin: 0 24px 0 0;
}

.EditMembers__input {
	max-width: 328px;
}
