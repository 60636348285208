.dropdown-menu {
  cursor: pointer;
}

.dropdown-menu__content {
  box-sizing: border-box;
  /* min-width: 120px; */
  z-index: 5;
  background-color: #7b8e95;
  border-radius: 15px;
  padding: 24px 19px 19px 17px;
}

.dropdown-menu__overlay {
  font-size: 14px;
  animation-duration: 0s !important;
}

.dropdown-menu__overlay .ant-dropdown-arrow {
  z-index: -1;
  background-color: #7b8e95;
  width: 40px;
  height: 40px;
  border: none;
  /* transform: rotate(45deg); */
  border-radius: 5px;
}

.dropdown-menu__item:hover {
  background-color: #7b8e95;
}

.dropdown-menu__overlay .ant-divider-horizontal {
  margin: 20px 0;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  padding: 0 12px;
}

.dropdown-menu__overlay .default-text {
  color: #fff;
}

.dropdown-menu__overlay ul {
  margin-bottom: 0;
}

.dropdown-menu__overlay p {
  margin-bottom: 0;
}

.dropdown-menu__text {
  margin: 0 50px 0 0;
  color: #fff;
}

.dropdown-menu__image {
  width: 35px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  cursor: pointer;
}

.dropdown-menu__item .ant-row .ant-row-middle {
  color: #000;
}

.dropdown-menu_divider {
  opacity: 0.5;
  border-top-color: #3d4b57 !important;
}
