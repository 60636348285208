.profile-card__recommendation__list-item {
	display: flex;
	list-style: none;
	padding: 16px 24px 16px 16px;
	border-bottom: 1px solid #f0f0f0;
}

.profile-card__recommendation__list-item button {
	display: flex;
	align-items: flex-end;
	cursor: pointer;
}

.profile-card__recommendation__list-item_opportunity {
	display: flex;
	flex: 3;
	flex-direction: column;
}

.profile-card__recommendation__list-item_organisation {
	display: flex;
	flex: 2;
	flex-direction: column;
}

.profile-card__recommendation__list-item_status {
	display: flex;
	flex: 2;
	align-items: flex-end;
	flex-direction: column;
	padding: 0 13px 0 0;
}

.profile-card__recommendation__list-item_title {
  margin: 0 0 4px 0;
	font-weight: bold;
	color: rgba(0, 0, 0, 0.65);
}

.profile-card__recommendation__list-item_text {
	margin: 0 0 4px 0;
	color: rgba(0, 0, 0, 0.65);
}

.profile-card__recommendation__list-item_buttons {
	display: flex;
	flex: 2;
	align-items: center;
	justify-content: flex-end;
}

.profile-card__recommendation_button {
	font-size: 14px;
	color: #1890ff;
	border: 1px solid #1890ff;
	border-radius: 5px;
	padding: 5px 16px;
	background: #ffffff;
}

.profile-card__recommendation_button_share {
	background: none;
	margin-right: 30px;
}

.profile-card__recommendation_button_share svg {
	width: 15px;
	height: 15px;
	color: #1890ff;
}
