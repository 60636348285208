.Admin-myteam__main {
	display: flex;
	min-height: 850px;
	flex-direction: column;
	justify-content: space-between;
}
.Admin-myteam__selects {
	display: flex;
	margin: 0 0 33px;
	justify-content: space-between;
}

.Admin-myteam__selects-item {
	display: flex;
	flex-direction: column;
}

.Admin-myteam__select {
	width: 148px;
}

.Admin-myteam__selects__title {
	margin: 0 0 5px;
	color: rgba(52, 58, 64, 0.85);
}

.Admin-myteam__select .ant-select-selector {
	display: flex;
	height: 22px !important;
	align-items: center;
	padding: 2px 11px 0 !important;
}

.Admin-myteam__members__item {
	border-bottom: 1px solid #f0f0f0;
	color: rgba(0, 0, 0, 0.85);
	padding: 16px 0 16px;
	font-weight: 300;
}

.Admin-myteam__members__item:first-child {
	color: #000000;
	font-weight: 400;
}

.Admin-myteam__members__item_name:nth-child(n+4) {
  justify-content: flex-end;
	display: flex;
}

.Admin-myteam__footer {
	display: flex;
	margin: 20px 0 0 0;
	justify-content: flex-end;
}

.Admin-myteam__members__item__button {
	border: 1px solid;
	cursor: pointer;
	color: #1890ff;
	padding: 4px 15px;
	border-radius: 5px;
	height: 32px;
}

.Admin-assigned__members__no-results {
	margin-top: 40px;
}
