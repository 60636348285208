.default-title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3D4B57;
}  

.default-title__large {
  font-weight: bold;
  font-size: 38px;
  color: #BDAB8A;
}


.default-title__grey {
  color: #566c76;
}

.default-title__big {
  font-size: 24px;
  font-weight: 500;
}

.default-title__semiBold {
  font-weight: 500;
}

.default-title__italic {
  font-style: italic;
}

.default-title__bold {
  font-weight: 700;
}

.default-title__thin {
  font-weight: 400;
}
