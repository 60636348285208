.notes-layout-notification {
  display: flex;
  white-space: pre-wrap;
  transform: translateY(-40px);
  opacity: 0;
  animation-name: appear;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  padding: 5px 15px;
  min-width: 200px;
  border: 1px solid #1ab759;
}
.notes-layout-notification_success {
  color: #1ab759;
  border-color: #1ab759;
  background-color: #f5fdf8;
}
.notes-layout-notification_error {
  color: #e93c3c;
  border-color:rgba(251, 78, 78, 0.5);
  background-color: #fef8f8;
}
.notes-layout-notification_info {
  color: #134979;
  border-color: #91d5ff;
  background-color: #e6f7ff;
}

.notes-layout-notification__text {
  flex: 1;
}
.notes-layout-notification__icon {
  margin-left: 20px;
  cursor: pointer;
  background: none;
}

.notes-notifications {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.notes-notifications__item {
  position: fixed;
  list-style: none;
  z-index: 1;
  top: 60px;
  padding: 0 20px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  max-width: 726px;
}

@media (max-height: 640px) {  
  .login-notifications__item {
    top: -10px;
  }
}


@keyframes appear {
  to {
    transform: translate(0);
    opacity: 1;
  }
}
