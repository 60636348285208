
.default-link {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #1890ff;
  text-decoration: none;
}

.default-link:hover {
  color: none;
  text-decoration: underline;
}

.default-link__golden {
  color: #BDAB8A;
}



