.aside {
  position: relative;
  width: 160px;
  max-height: 280px;
  padding-right: 64px;
  box-sizing: content-box;
  background-color: #ddd;
  line-height: normal;
}

.aside::after {
  content: '';
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100%;
  background-color: #ddd;
  z-index: 1;
  position: absolute;
}

.nav-icon {
  width: 20px;
}

.nav-icon-active {
  display: none;
}


.nav-menu__item {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 12px 0 12px 24px;
  /* padding-left: 24px; */
}
.nav-menu__link-active {
  border-radius: 0 10px 10px 0;
  background-color: #3D4B57;
}

.nav-menu__link-active .nav-icon {
  display: none;
}

.nav-menu__link-active .nav-icon-active {
  display: block;
}

.nav-menu__link-active .nav-text .default-text {
  color: #fff;
}

.nav-text {
  margin-left: 8px;
}
