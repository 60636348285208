.login-page {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/login_background.jpg");
  background-size: cover;
  height: 100vh;
}
.login-header {
  padding: 36px 30px;
  background-color: #E3E4E8;
}
.login-card {
  width: 330px;
  display:flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
  margin: auto;
  background-color: #F8F9FA;
}
.login-notifications {
  position: relative;
  display: flex;
  justify-content: center;
}
.login-notifications__item {
  position: absolute;
  z-index: 1;
  top: 60px;
  padding: 0 20px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  max-width: 726px;
}

@media (max-height: 640px) {  
  .login-notifications__item {
    top: -10px;
  }
}
