.Admin-assigned__main {
	display: flex;
	min-height: 850px;
	flex-direction: column;
	justify-content: space-between;
}
.Admin-assigned__selects {
	display: flex;
	margin: 0 0 33px;
	justify-content: space-between;
}

.Admin-assigned__main__success {
	margin: 24px 0;
}

.Admin-assigned__main__success .ant-alert-message {
	color: #407227;
}

.Admin-assigned__selects-item {
	display: flex;
	flex-direction: column;
}

.Admin-assigned__select {
	width: 120px;
	height: 22px;
}

.Admin-assigned__selects__title {
	margin: 0 0 5px;
	color: rgba(52, 58, 64, 0.85);
}

.Admin-assigned__select .ant-select-selector {
	display: flex;
	height: 22px !important;
	align-items: center;
	padding: 2px 11px 0 !important;
}

.Admin-assigned__members__header {
	display: flex;
	margin: 0 0 10px;
	padding: 0 18px 15px;
	justify-content: space-between;
	border-bottom: 1px solid #aeb5bd;
}

.Admin-assigned__members__header__title {
	font-size: 14px;
	color: #aeb5bd;
}

.Admin-assigned__members__header__checkbox__text {
	margin: 0 5px 0 0;
	color: #1890ff;
	font-size: 12px;
}

.Admin-assigned__footer {
	display: flex;
	margin: 20px 0 0 0;
	flex-direction: column;
	align-items: flex-end;
}

.Admin-assigned__members__item__button {
	border: 1px solid;
	cursor: pointer;
	color: #1890ff;
	padding: 4px 15px;
	border-radius: 5px;
	height: 32px;
}

.Admin-assigned__footer_buttons {
	margin-top: 22px;
}

.Admin-assigned__footer_button__remove {
	margin-right: 16px;
}

.Admin-assigned__members .ant-checkbox-group-item {
	position: relative;
	margin-right: 0;
	min-width: 100%;
}

.Admin-assigned__members .ant-checkbox {
	position: absolute;
	top: 27px;
	right: 18px;
}

.Admin-assigned__members__header .ant-checkbox {
	position: relative;
	top: 0;
	right: 0;
}

.Admin-assigned__members .ant-checkbox + span {
	min-width: 100%;
	padding: 0;
}

.Admin-assigned__members__header__checkbox {
	display: flex;
}
