.profile-card__main__content__wrapper {
  width: 100%;
  padding-top: 30px;
  padding-right: 63px;
  padding-left: 63px;
}
.profile-card__content {
	min-height: 850px;
  display: flex;
}
.profile-card__content__description-row {
  margin-top: 43px;
  margin-bottom: 80px;
}
.profile-card__content__description-column:last-child {
  margin-right: 0
}
.profile-card__content__description-column__title {
  margin-bottom: 16px;
}
.profile-card__content__description-column__link {
  display: flex;
  align-items: center;
}
.profile-card__content__description-column__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.profile-card__content__description-column__item_row {
  flex-direction: row;
}
.profile-card__content__description-column__item__date {
  margin-right: 1em;
}
.profile-card__content__description-column__icon {
  margin-left: 8px;
}
