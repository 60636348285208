.login-layout-notification {
  white-space: pre-wrap;
  transform: translateY(-40px);
  opacity: 0;
  animation-name: appear;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  padding: 5px 15px;
  border: 1px solid #1ab759;
}
.login-layout-notification_success {
  color: #1ab759;
  border-color: #1ab759;
  background-color: #f5fdf8;
}
.login-layout-notification_error {
  color: #e93c3c;
  border-color:rgba(251, 78, 78, 0.5);
  background-color: #fef8f8;
}

@keyframes appear {
  to {
    transform: translate(0);
    opacity: 1;
  }
}
