.profile-card__recommendation__list-item__header {
  display: flex;
  justify-content: space-between;
}

.profile-card__recommendation__filters {
  display: flex;
  align-items: flex-end;
	justify-content: space-between;
}
.profile-card__recommendation__filters_show {
	color: #aeb5bd;
}

.profile-card__recommendation__list {
	margin: 18px 0 0 0;
}
