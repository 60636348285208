.login-page .ant-divider-horizontal.ant-divider-with-text-center::before,
.login-page .ant-divider-horizontal.ant-divider-with-text-center::after
{
   border-top: 1px solid #D9D9D9;
}
.login-page button {
  cursor: pointer;
}
.login-title {
  text-align: center;
  margin-bottom: 15px;
}
.login-subtitle {
  margin-bottom: 20px;
}
.login-subtitle span{
  text-align: center;
}
.login-card__wrapper {
  display: flex;
  transition: .3s;
  /* transform: translateX(-330px); */
}
.login-card__content {
  position: relative;
  padding: 24px;
  width: 330px;
}
.login-card__return-button {
  background: none;
}
.divider-container {
  width: 100%;
  margin: 8px 0;
  color: #AEB5BD;
}
.divider-container .secondary {
  font-size: 16px;
}
.sign-field {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  margin-top: 24px;
  background-color: #F8F9FA;
  border-radius: 5px;
}
footer.sign-field {
  height: unset;
}
form {
  width: 100%;
}
form .sign-field:first-child {
  margin-top: 0;
}

.sign-field.login-google {
  position: relative;
  background-color: #fff;
  font-size: 16px;
  border: 0.5px solid #D9D9D9;
}
.login-google_image {
  position: absolute;
  left: 13px;
}
.sign-field.login-regular {
  border: 0.5px solid #D9D9D9;
  font-size: 16px;
  background-color: #BDAB8A;
}
.input {
  box-sizing: border-box;
  width: 282px;
  height: 40px;
  padding: 8px 12px 8px 32px;
  border: none;
  background-color: transparent;
}
.username {
  padding: 8px 12px 8px 32px;
}
.password {
  padding: 8px 32px;
}
.sign-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sign-field__small .ant-form-item-control-input{
  min-height: unset;
}

.remember {
  margin-right: 8px;
}

.forgot-password {
  background-color: transparent;
}
