.notesAsideItem__card__wrapper {
	display: flex;
	margin: 0 0 22px 0;
	width: 100%;
	border-bottom: 1px solid #D9D9D9;
  align-items: flex-start;
  justify-content: space-between;
}

.notesAsideItem__card__item_wrapper {
	display: flex;
}

.notesAsideItem__card__item_wrappericon {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.notesAsideItem__card__item_icon {
	display: flex;
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	outline: none;
	border-radius: 32px;
	background: #1890FF;
	border: 1px solid #1890FF;	
	font-size: 16px;
	align-items: center;
	justify-content: center;
}
.notesAsideItem__card__item_icon svg {
	color: #FFF;
}

.notesAsideItem__card__item_icon_complete {
  position: relative;
	background: #FFFFFF;
	border: 1px solid #AEB5BD;	
}

.notesAsideItem__card__item_text_title_deleted {
	text-decoration: line-through;
}

.notesAsideItem__card__item_icon_complete svg {
	color: #AEB5BD;
}

.notesAsideItem__card__item_icon_complete::after {
  content: "";
  position: absolute;
  top: 37px;
	width: 1px;
	height: 10px;
	background: #AEB5BD;
}

.notesAsideItem__card__item_text {
	margin: 0 10px 33px 16px;
	display: flex;
	min-height: 44px;
	flex-direction: column;
}

.notesAsideItem__card__item_text_title {
	color: #1890ff;
	font-weight: bold;
}

.notesAsideItem__card__item_text_title_complete {
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
}

.notesAsideItem__card__item_text_subtitle {
	color: #999999;
}

.notesAsideItem__card__item_buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.notesAsideItem__card__item_edit {
  cursor: pointer;
	display: block;
  background: none;
	color: #1890FF;
}

.notesAsideItem__card__item_edit:disabled {
	color: #AEB5BD;
  cursor: not-allowed;
}

.notesAsideItem__card__item_delete {
  cursor: pointer;
	display: block;
  background: none;
	color: #ff4d4f;
}

.notesAsideItem__card__item_delete button:disabled {
	color: #AEB5BD;
	background: none;
  cursor: not-allowed;
}
