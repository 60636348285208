.members-list-card {
  padding-top: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
}

.members-list-card__avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color:olive;
}

.members-list-card__avatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.members-list-card_rating {
	margin: 15px 0 0 35px;
	color:rgba(0, 0, 0, 0.50);
}


.members-list-card__details {
  flex: 0.4 0.4;
  list-style: none;
  margin-bottom: 10px;
  margin-left: 24px;

}
.members-list-card__details__main {
  font-style: italic;
  font-weight: 500;
}

.members-list-card__details li {
  display: flex;
  margin-bottom: 8px;
  vertical-align: top;
}

.members-list-card__details li:last-of-type {
  margin-bottom: 0px;
}

.members-list-card__details__icon {
  height: 14px;
  width: 14px;
  margin-right: 9px;
  vertical-align: middle;
  text-align: center;
}

.members-list-card__wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
  margin-bottom: 10px;
}

.members-list-card__wrap__title {
  color: #595959;
}

.members-list-card__wrap__title span {
  word-break: break-word;
}

.members-list-card__wrap__technology-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 0;
}

.members-list-card__wrap__technology-list__item {
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 1px 8px;
  background-color: #F5F5F5;
  color: #595959;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  white-space: nowrap;
}

.members-list-card__wrap__technology-list li:last-of-type {
  margin-right: 0;
}

.members-list-card__wrap-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 19px;
  margin-left: 36px;
}

.members-list-card__wrap-buttons__contacts {
  cursor: pointer;
  background-color: transparent;
  margin-top: 13px;
}

.members-list-card__wrap-buttons__add {
	margin: 0 0 2px 0;
  height: 12px;
  width: 12px;
}

.members-list-card__wrap-buttons__view-button {
  color: #40A9FF;
  background-color: #FFFFFF;
  border: 1px solid #40A9FF;
  border-radius: 5px;
  /* padding: 5px 16px; */
  /* margin-right: 6px; */
}

.members-list-card__wrap-link {
  border: 1px solid;
  padding: 4px 15px;
  border-radius: 5px;
}
