.pagination-button {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  margin-right: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
}

.pagination-button__content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.pagination-button_disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.pagination-button_active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}

.pagination-button_active .pagination-button__content {
  color: #1890ff;
}
