.profile-card__activity__list-item__header__right-side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.profile-card__activity__list-item__header__left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profile-card__activity__list-item__title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.profile-card__activity__list-item__title__text {
  margin-right: 20px;
}
.profile-card__activity__list-item {
  list-style: none;
  margin-top: 25px;
}
.profile-card__activity__list-item__edit {
  display: flex;
}
.profile-card__activity__list-item__edit svg {
  margin: 1px 20px 0 0;
} 
.profile-card__activity__list-item__skills {
  display: flex;
  margin-top: 20px;
}
.profile-card__activity__list-item__date {
  margin-bottom: 20px;
}
.profile-card__activity__list-item__skills__item {
  list-style: none;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 1px 8px;
  background-color: #F5F5F5;
  color: #595959;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  white-space: nowrap;
}
.profile-card__activity__list-item__skills__item:last-child {
  margin-right: 0;
}
.profile-card__activity__list-item__description {
 margin-top: 20px; 
}
.profile-card__activity__list-item__description__view-more {
  margin-top: 15px;
  cursor: pointer;
}
.profile-card__activity__list-item .divider {
  margin-top: 16px;
}
