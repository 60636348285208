.content {
  padding: 40px;
  margin-right: 37px;
  margin-bottom: 64px;
  min-height: 780px;
  background: #F8F9FA;
}

.welcome {
  min-height: 375px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 40px;
}

.welcome__header {
  margin-bottom: 18px;
}

.welcome__subheader {
  margin-bottom: 36px;
}

.welcome__button {
  margin: auto 0 0 auto;
  border-radius: 5px;
  color: #FFFFFF;
  background: #1890FF;
  padding: 5px 16px 5px;
}

.welcome__button:hover,
.welcome__button:focus {
  color: #FFFFFF;
}

.home-groups {
  margin-top: 70px;
}

.home-slider:last-child {
  margin-top: 50px;
}

.home-slider__title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.home-slider__filter {
  width: 200px;
  margin-left: 11px;
}
