.one-step-pagination {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  list-style: none;
}

.pagination-button:last-child {
  margin: 0;
}

.one-step-pagination .pagination-button {
  list-style: none;
}

.pagination-button_prev, .pagination-button_next {
  font-size: 12px;
}
