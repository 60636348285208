.members-view {
  display: flex;
  padding-left: 32px;
  background-color: #f8f9fa;
  margin-right: 37px;
  margin-bottom: 64px;
  min-height: 780px;
}
.content {
  padding: 40px;
  margin-right: 37px;
  margin-bottom: 64px;
  min-height: 780px;
  background: #f8f9fa;
}
.members-view__aside {
  max-width: 315px;
  min-width: 240px;
  padding-right: 76px;
}
@media screen and (min-width: 1400px) {
  .members-view__aside {
    padding-right: 36px;
  }
}
.members-view__aside-filters {
  flex-grow: 1;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  padding-top: 26px;
}
.members-view__main-filters__clear {
  cursor: pointer;
  margin-left: auto;
  margin-right: 22px;
  /* display: flex; */
  background: none;
  color: #1890FF;
}
.members-view__main-filters__clear span {
  font-size: 16px;
}
.members-view__aside-filters-block-wrapper {
  margin-bottom: 45px;
}
.members-view__main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.members-view__list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #ffff;
  border-radius: 15px 15px 15px 0;
  padding: 0 26px;
}
.members-view__list-content {
  flex-grow: 1;
}
.members-view__list-content-wrapper {
  /* max-width: 700px; */
  margin: 0 50px;
}
.members-view__list-pagination {
  align-self: flex-end;
  margin-top: 20px;
  margin-bottom: 32px;
}
.members-view__main-filters,
.members-view__aside-title {
  display: flex;
  align-items: center;
  height: 70px;
}
.members-view__filter-select {
  background-color: #ffff;
  margin: 0 7px;
}
.members-view__aside-filters-list {
  display: flex;
  flex-direction: column;
}
.members-view__aside-filters-list .ant-checkbox-wrapper {
  margin-left: 0;
  font-weight: 300;
}
.members-view__list-content-header {
  padding-top: 35px;
  padding-bottom: 24px;
  color: #AEB5BD;
}

.members-view__list-content-header__no-results  {
  padding-top: 85px;
}

.members-view__aside-filters-list
  .ant-checkbox-wrapper-checked {
  color: #1890ff;
}
.members-view__filter-select .ant-select-selector {
  border-radius: 5px !important;
}
.members-view__aside-filters-collapse .members-view__aside-filters-panel
 {
  padding-left: 0;
  overflow: hidden;
  /* background: #f7f7f7; */
  border-radius: 2px;
  border: none;
  border-top: 1px solid #e0e0e0;
}
.members-view__aside-filters-collapse .members-view__aside-filters-panel:first-child {
  border: none; 
}
.members-view__aside-filters-collapse .members-view__aside-filters-panel:last-child {
  border-bottom: 1px solid #e0e0e0;
}
.members-view__aside-filters .members-view__aside-filters-collapse .members-view__aside-filters-panel .ant-collapse-arrow {
  padding-top: 5px;
  right: 0;
}
.members-view__aside-filters-collapse .members-view__aside-filters-panel .ant-collapse-header {
  padding: 5px 0;
}
.members-view__aside-filters-panel .ant-collapse-content {
  background-color: inherit;
  padding-bottom: 25px;
}
.members-view__aside-filters-collapse,
.members-view__aside-filters-panel .ant-collapse-content {
  border: none;
}
.members-view__aside-filters-panel .ant-collapse-content-box {
  padding: 0;
}
.members-view__aside-filters-button-container {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}
.members-view__aside-filters-button {
  border-radius: 5px;
  width: 180px;
}
