.CardMember-card__person__image {
	text-align: center;
}

.CardMember-list__card__avatar {
	object-fit: cover;
	border-radius: 50%;
	height: 80px;
	width: 80px;
}

.CardMember-card__person__title {
	margin: 24px 0 37px;
	color: #212529;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
}
