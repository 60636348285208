.slider-card {
  width: 320px;
  height: 270px;
  padding: 16px 24px 22px 24px;
}

.slider-card.default-card__float {
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
}

.slider-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-card__data {
  display: flex;
  margin: 20px 0;
}

.slider-card__avatar {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 16px;
  border-radius: 50%;
  /* background: grey; */
}

.slider-card__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.slider-card__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  list-style: none;
}

.slider-card__details-icon {
  width: 15px;
  margin-right: 8.25px;
}

.slider-card__details-item {
  display: flex;
  margin-top: 8px;
}

.slider-card__details-item:first-child {
  margin-top: 0;
}

.slider-card__description span {
  word-break: break-all;
}
