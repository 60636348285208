.list-schedule {
  max-width: 590px;
}

.list-schedule .ant-divider {
  margin-top: 18px;
  margin-bottom: 24px;
}

.list-schedule__item {
  width: 100%;
  padding-bottom: 15px;
  align-items: center;
  display: flex;
  list-style: none;
}

.list-schedule__time {
  width: 85px;
}

.list-schedule__icon {
  position: relative;
  margin-right: 15px;
}

.list-schedule__icon::after {
  content: "";
  position: absolute;
  height: 22px;
  width: 2px;
  background-color: #CED4DA;
  bottom: -20px;
  right: calc(50% - 1px);
}

.list-schedule__title {
  line-height: 11px;
  width: 100px;
  margin-right: 10px;
}

.list-schedule__button {
  border: 1px solid #1890FF;
  border-radius: 5px;
  margin-left: auto;
}

.list-schedule__dash-row {
  margin-top: 5px;
  height: 22px;
}
