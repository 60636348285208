.Admin__main {
	width: 100%;
	padding: 40px 80px 20px;
}

.Admin__main-title {
	display: flex;
	margin: 0 0 16px 0;
	flex-direction: row;
	justify-content: space-between;
}

.Admin__main-item__button svg {
	margin: 0 0 0 5px;
}

.Admin__main-item__title_sub {
	font-weight: 300;
  font-size: 24px;
  line-height: 22px;
  color: #3D4B57;
}

.Admin__row_radio {
	margin: 10px 0 18px 0;
}

.Admin__main-item__row {
	margin-top: 10px;
	margin-bottom: 40px;
}

.Admin__main-item__row_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: #3d4b57;
}

.Admin__main-item__col {
	display: flex;
	flex-direction: column;
}

.Admin_modal__main-item__col-title {
	font-weight: 500;
	color: #3d4b57;
	font-size: 12px;
	line-height: 28px;
}

.Admin_modal__main-item__col-text {
  color: #595959;
	font-size: 14px;
	font-weight: 300;
}

.Admin_modal__main-item_footer {
	margin: 18px 0 0 0;
	display: flex;
	flex-direction: column;
}

.Admin__main-item__col .ant-radio-button-wrapper-disabled {
	color: rgba(59, 75, 87, 0.65);
	background: #fff;
}
.Admin__main-item__col .ant-radio-button-wrapper-checked {
  color: #1890ff;
  background: #fff;
	border-color: #1890ff;
}

.Admin_modal .radio__group__button span{
	font-size: 12px;
}

.Admin_modal .radio__group__button .ant-radio-button-wrapper {
	padding: 0 15px;
}

.Admin_modal .ant-radio-button-wrapper:not(:first-child)::before {
	background: #1890ff;
}
