.share-modal .ant-modal-footer {
  padding-right: 0;
}

.share-modal__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
	height: 80px;
	width: 80px;
	border-radius: 50%;
	margin-right: 20px;
	background-color: #bdab8a;
}

.share-modal__avatar__container {
  width: 100%;
  height: 100%;
  margin: auto;
}

.share-modal__avatar__icon {
  width: 50px;
  height: 37px;
}

.share-modal__side {
	padding: 30px;
}

.share-modal__side:first-child {
  padding-left: 10px;
  border-right: 1px solid #d6d9d6;
}

.share-modal__side:last-child {
  padding-right: 10px;
}

.share-modal__details-item {
  display: flex;
  align-items: center;
	margin-top: 8px;
}
.share-modal__details-item__icon {
  display: flex;
  align-items: center;
	width: 20px;
}

.share-modal__description {
	margin-top: 24px;
	color: #3d4b57;
}

.share-modal__tags {
	margin-top: 20px;
}

.share-modal__tags-item {
	font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 1px 8px;
  background-color: #F5F5F5;
  color: #595959;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  white-space: nowrap;
}

.share-modal__form-item__wrapper {
  margin-bottom: 0;
}

.share-modal__form p {
	margin-bottom: 5px;
}

.share-modal__form .share-modal__textarea {
	resize: none;
}

.share-modal__form
.ant-select-open
.ant-select-selector
.ant-select-selection-item {
  color: #fff;
}

.share-modal__form
.ant-select-open
.ant-select-selector
.ant-select-selection-placeholder{
  color: #fff;
}

.share-modal__form .ant-form-item {
  margin-bottom: 12px !important;
  min-height: 56px;
}
