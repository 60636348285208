.profile-card__aside {
  box-sizing: border-box;
  max-width: 316px;
  min-width: 316px;
  padding-top: 64px;
  padding-left: 50px;
  padding-right: 35px;
}
.profile-card__person {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}
.profile-card__person__image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: grey;
}
.profile-card__person__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profile-card__person__title {
  margin: 24px 0;
}
.profile-card__person__details {
  margin-right: auto;
}
.profile-card__person__details-item__icon {
  width: 13px;
  margin-right: 9.5px;
}
.profile-card__person__details-item {
  display: flex;
  margin-bottom: 12px;
}
.profile-card__person__details-item:last-child {
  margin-bottom: 0;
}
.profile-card__skills {
  padding-top: 10px;
}
.profile-card__skills__list {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}
.profile-card__section__title {
  margin-bottom: 8px;
}
.tag-label {
  list-style: none;
  text-align: center;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 1px 8px;
  background-color: #f5f5f5;
  color: #595959;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  white-space: nowrap;
}
.profile-card__divider {
  margin: 34px 0;
}
