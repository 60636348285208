.profile-card__activity__list-item__header {
  display: flex;
  justify-content: space-between;
}

.profile-card__activity__filters {
  display: flex;
}

.profile-card__activity__filters__select {
  width: 140px;
  margin-right: 25px;
}

.profile-card__activity__filters__input {
  width: 160px;
  height: 32px;
  border-radius: 5px;
}

.profile-card__activity__list-item {
  list-style: none;
  margin-top: 25px;
}

.profile-card__activity__list-item__skills {
  display: flex;
  margin-top: 20px;
}

.profile-card__activity__list-item__skills__item {
  list-style: none;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 1px 8px;
  background-color: #F5F5F5;
  color: #595959;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  white-space: nowrap;
}

.profile-card__activity__list-item__description__view-more {
  margin-top: 15px;
}


