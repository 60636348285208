.select-menu *,  .select-menu__dropdown *, .select-menu .ant-select .ant-select-selector{
  animation-duration: 0s !important;
  /* transition: all 0s !important; */
}

.select-menu .ant-select .ant-select-selector {
  border: none;
  border-radius: 5px 5px 0 0;
  box-shadow: none !important;
  background: transparent;
  color: #1890FF;
  outline: none;
}

.ant-select-focused {
  border: none !important;
}

.select-menu .ant-select:not(.ant-select-open) .ant-select-arrow path {
  transition: 0.3s;
  fill: #1890FF;
}

.select-menu .ant-select-open .ant-select-selector {
  border: none;
  background-color: #343A40;
}


.select-menu .ant-select:not(.ant-select-open) .ant-select-selector:hover {
  transition: 0.3s;
  text-decoration: underline; 
} 

.select-menu .ant-select-open  .ant-select-selection-item {
  color: #fff;
}


.select-menu__dropdown {
  animation: none;
  border-radius: 0 0 5px 5px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #7B8E95;
}

.select-menu__item {
  height: 1em;
  transition: none;
  padding-right: 40px;
  color: #fff;
}

.select-menu__item:hover {
  background-color: #343A40;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #343A40;
  color: #fff;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #fff;
  background-color: #343A40;
}

