.base-modal .ant-modal-content {
  padding: 0 24px;
  border-radius: 10px;
  background-color: #f4f5f6;
}

.base-modal .ant-modal-header {
  padding-left: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #d6d9d6;
}

.base-modal .ant-modal-footer {
  border-top: 1px solid #d6d9d6;
}

.base-modal .ant-modal-body {
  padding: 0;
}

.base-modal .ant-modal-close-icon svg {
  fill: #1890ff;
}

.base-modal .ant-btn.ant-btn-primary[disabled] {
  background-color: #aeb5bd;
  color: #fff;
}
